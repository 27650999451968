import { MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import strings from '~/data/strings'

import generateHeadProps from '~/utils/generate-head-props'

import PagePrivate from '~/components/PageFallback/PageFallback'

export const loader = async () => {
  // Switch to CMS data here if needed
  return { strings: strings.pagePrivate }
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const { head } = data.strings
  return generateHeadProps(head.title, head.description, head.keywords)
}

export default function PageTestRoute() {
  const data = useLoaderData<typeof loader>()
  return <PagePrivate strings={data.strings.body} />
}
